import { createSlice } from "@reduxjs/toolkit";

const commonSlice = createSlice({
  name: "common",
  initialState: {
    isDrawerOpen: false
  },
  reducers: {
    openDrawer(state, action) {
      state.isDrawerOpen = true;
    },
    closeDrawer(state) {
      state.isDrawerOpen = false;
    }
  }
});

export const { openDrawer, closeDrawer } = commonSlice.actions;

export default commonSlice.reducer;
