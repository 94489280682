export const MOBILE_WIDTH = "500px";

export const API_STATUS = {
  IDLE: "IDLE",
  LOADING: "LOADING",
  RESOLVED: "RESOLVED",
  REJECTED: "REJECTED"
};

export const SERVICE_METHODS = {
  POST: "POST",
  GET: "GET",
  PUT: "PUT"
};

export const statusList = [
  { label: "Dispatched", value: "dispatched" },
  { label: "Processing 1", value: "processing_1" },
  { label: "Processing 2", value: "processing_2" },
  { label: "Received", value: "received" },
  { label: "Delivered", value: "delivered" }
];

export const stateList = [
  { label: "Andhra Pradesh", value: "Andhra Pradesh" },
  { label: "Arunachal Pradesh", value: "Arunachal Pradesh" },
  { label: "Assam", value: "Assam" },
  { label: "Bihar", value: "Bihar" },
  { label: "Chhattisgarh", value: "Chhattisgarh" },
  { label: "Goa", value: "Goa" },
  { label: "Gujarat", value: "Gujarat" },
  { label: "Haryana", value: "Haryana" },
  { label: "Himachal Pradesh", value: "Himachal Pradesh" },
  { label: "Jammu and Kashmir", value: "Jammu and Kashmir" },
  { label: "Jharkhand", value: "Jharkhand" },
  { label: "Karnataka", value: "Karnataka" },
  { label: "Kerala", value: "Kerala" },
  { label: "Madhya Pradesh", value: "Madhya Pradesh" },
  { label: "Maharashtra", value: "Maharashtra" },
  { label: "Manipur", value: "Manipur" },
  { label: "Meghalaya", value: "Meghalaya" },
  { label: "Mizoram", value: "Mizoram" },
  { label: "Nagaland", value: "Nagaland" },
  { label: "Odisha", value: "Odisha" },
  { label: "Punjab", value: "Punjab" },
  { label: "Rajasthan", value: "Rajasthan" },
  { label: "Sikkim", value: "Sikkim" },
  { label: "Tamil Nadu", value: "Tamil Nadu" },
  { label: "Telangana", value: "Telangana" },
  { label: "Tripura", value: "Tripura" },
  { label: "Uttarakhand", value: "Uttarakhand" },
  { label: "Uttar Pradesh", value: "Uttar Pradesh" },
  { label: "West Bengal", value: "West Bengal" },
  {
    label: "Andaman and Nicobar Islands",
    value: "Andaman and Nicobar Islands"
  },
  { label: "Chandigarh", value: "Chandigarh" },
  { label: "Dadra and Nagar Haveli", value: "Dadra and Nagar Haveli" },
  { label: "Daman and Diu", value: "Daman and Diu" },
  { label: "Delhi", value: "Delhi" },
  { label: "Lakshadweep", value: "Lakshadweep" },
  { label: "Puducherry", value: "Puducherry" }
];

export const mbaseList = [
  { label: "0", value: "0" },
  { label: "2", value: "2" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
  { label: "10", value: "10" },
  { label: "12", value: "12" },
  { label: "14", value: "14" },
  { label: "16", value: "16" },
  { label: "18", value: "18" },
  { label: "20", value: "20" },
  { label: "22", value: "22" }
];

export const agentNameList = [
  { label: "Mr. Abbasali Samuwala", value: "Mr. Abbasali Samuwala" },
  { label: "Mr. Abhijeet Oza", value: "Mr. Abhijeet Oza" },
  { label: "Mr. Adaviprasanna Navratna", value: "Mr. Adaviprasanna Navratna" },
  { label: "Mr. Ajay Tuli", value: "Mr. Ajay Tuli" },
  { label: "Mr. Akshay Kotian", value: "Mr. Akshay Kotian" },
  { label: "Mr. Amit Uniyal", value: "Mr. Amit Uniyal" },
  { label: "Mr. Anil Sharma", value: "Mr. Anil Sharma" },
  { label: "Mr. Danny  Dias", value: "Mr. Danny  Dias" },
  { label: "Mr. Delish Kumar KK", value: "Mr. Delish Kumar KK" },
  { label: "Mr. Dinesh Dablipuram", value: "Mr. Dinesh Dablipuram" },
  { label: "Mr. Divyesh Shah", value: "Mr. Divyesh Shah" },
  { label: "Mr. Gaurav Chauhan", value: "Mr. Gaurav Chauhan" },
  { label: "Mr. Gunavarman S.K", value: "Mr. Gunavarman S.K" },
  { label: "Mr. Hardik Shah", value: "Mr. Hardik Shah" },
  { label: "Mr. Jacob George", value: "Mr. Jacob George" },
  { label: "Mr. Kailashsingh Rawat", value: "Mr. Kailashsingh Rawat" },
  {
    label: "Mr. Manish Bhagatsingh Singani",
    value: "Mr. Manish Bhagatsingh Singani"
  },
  { label: "Mr. Pardha Saradhi", value: "Mr. Pardha Saradhi" },
  { label: "Mr. Prabhat Kumar Singh", value: "Mr. Prabhat Kumar Singh" },
  {
    label: "Mr. Prakash Singh Hoshiar Bisht",
    value: "Mr. Prakash Singh Hoshiar Bisht"
  },
  { label: "Mr. Rajur Chabria", value: "Mr. Rajur Chabria" },
  { label: "Mr. Rana Roy", value: "Mr. Rana Roy" },
  { label: "Mr. Ravi Pahwa", value: "Mr. Ravi Pahwa" },
  { label: "Mr. Ravindra Dwivedi", value: "Mr. Ravindra Dwivedi" },
  { label: "Mr. Rishabh Chadha", value: "Mr. Rishabh Chadha" },
  { label: "Mr. Rishi Dhawan", value: "Mr. Rishi Dhawan" },
  { label: "Mr. Rohit Tanwar", value: "Mr. Rohit Tanwar" },
  { label: "Mr. Sameer Gujar", value: "Mr. Sameer Gujar" },
  { label: "Mr. Sandip Das", value: "Mr. Sandip Das" },
  { label: "Mr. Santosh Kesarkar", value: "Mr. Santosh Kesarkar" },
  { label: "Mr. Sathish Kumar", value: "Mr. Sathish Kumar" },
  { label: "Mr. Saurabh Gupta ", value: "Mr. Saurabh Gupta " },
  {
    label: "Mr. Sharif Abdulkadher Madhar A",
    value: "Mr. Sharif Abdulkadher Madhar A"
  },
  { label: "Mr. Shivakumar Venkata", value: "Mr. Shivakumar Venkata" },
  { label: "Mr. Shivam Chaudhary", value: "Mr. Shivam Chaudhary" },
  { label: "Mr. Sujit Pandey", value: "Mr. Sujit Pandey" },
  {
    label: "Mr. Surendra Singh Shekhawat",
    value: "Mr. Surendra Singh Shekhawat"
  },
  { label: "Mr. Vinayak Ganiger", value: "Mr. Vinayak Ganiger" },
  { label: "Mr. Viney Pathania", value: "Mr. Viney Pathania" },
  { label: "Mr. Vinod Kadam", value: "Mr. Vinod Kadam" },
  { label: "Mr. Vishal Patel", value: "Mr. Vishal Patel" },
  { label: "Mr. Yatindra Singh", value: "Mr. Yatindra Singh" },
  { label: "Mrs. Pooja Bagul ", value: "Mrs. Pooja Bagul " },
  { label: "Mrs. Sushma Desai ", value: "Mrs. Sushma Desai " }
];
