import { axiosInstance } from "../../commons/axiosConfig";
import { SERVICE_METHODS } from "../../commons/constants";
import { makeURL, getToken } from "../../commons/appUtils";

export const getOrderList$$ = page => {
  return axiosInstance({
    method: SERVICE_METHODS.GET,
    url: makeURL(`/coupon/list/order`),
    params: {
      page
    },
    ...getToken()
  });
};

export const getOrderDetails$$ = orderId => {
  return axiosInstance({
    method: SERVICE_METHODS.GET,
    url: makeURL(`/coupon/order/details/${orderId}`),
    ...getToken()
  });
};

export const updateOrder$$ = (orderId, data) => {
  return axiosInstance({
    method: SERVICE_METHODS.PUT,
    url: makeURL(`/coupon/order/status/update/${orderId}`),
    data,
    ...getToken()
  });
};
