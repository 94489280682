import React from "react";
import { useSelector } from "react-redux";
import ApplyCouponModal from "./ApplyCouponModal";
import { useDispatch } from "react-redux";
import { closeModal } from "./ModalSlice";
import AddAddressModal from "./AddAddressModal";

const modalLookUp = {
  ApplyCouponModal,
  AddAddressModal
};

const ModalManager = () => {
  let renderedModal;
  const currentModal = useSelector(state => state.modals);
  const dispatch = useDispatch();

  if (currentModal) {
    const destroyModal = () => {
      dispatch(closeModal());
    };
    const { modalType, modalProps } = currentModal;
    const ModalComponent = modalLookUp[modalType];

    renderedModal = (
      <ModalComponent closeModal={destroyModal} {...modalProps} />
    );
  }
  return <span>{renderedModal}</span>;
};

export default ModalManager;
