import React, { useState, useMemo } from "react";
import { Row, Col, Form, Input, Spin } from "antd";
import {
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  LoadingOutlined,
  DeleteOutlined,
  PlusCircleOutlined
} from "@ant-design/icons";
import { css } from "emotion";
import { API_STATUS, MOBILE_WIDTH } from "../../commons/constants";
import { errorNotification } from "../../commons/appUtils";
import { useDispatch } from "react-redux";
import { registrationRules } from "../auth/authValidations";
import { openModal } from "../modals/ModalSlice";
import { get as lodashGet } from "lodash";
import {
  useUpdateProfile,
  useDeleteAddress,
  useUpdateAddress
} from "./userHooks";

const Details = ({ user }) => {
  return (
    <>
      <h2>User Info:</h2>
      <Row gutter={10}>
        <InfoItem editable={false} label="Store Name" value={user.store_name} />
        <InfoItem editable={false} label="First Name" value={user.first_name} />
        <InfoItem editable={false} label="Last Name" value={user.last_name} />
        <InfoItem editable={false} label="SUPROL Sales Manager" value={user.agent_name} />
        {/* <InfoItem
          editable={false}
          label="Distributor"
          value={user.distributor}
        /> */}
        <InfoItem
          editable={true}
          label="Email"
          value={user.email}
          fieldName="email"
          placeholder="Email"
          ruleName="emailRules"
        />
        <InfoItem editable={false} label="Phone Number" value={user.username} />
        <InfoItem editable={false} label="GST Number" value={user.gst_number} />
      </Row>
      <div className={styles.addressListContainer}>
        {useMemo(
          () => (
            <AddressList addressList={user.address || []} />
          ),
          [user.address]
        )}
      </div>
    </>
  );
};

const InfoItem = ({
  editable,
  label,
  value,
  fieldName,
  placeholder,
  ruleName
}) => {
  const [isEditableModeActive, setEditableMode] = useState(false);

  const toggleEditableMode = newValue => () => {
    setEditableMode(newValue);
  };

  const details = (
    <>
      <div className={styles.labelView}>
        <h3 className={styles.label}>{`${label}:`}</h3>
        {editable && (
          <EditOutlined
            className={styles.editIcon}
            onClick={toggleEditableMode(true)}
          />
        )}
      </div>
      <p>{value}</p>
    </>
  );

  return (
    <Col xs={24} sm={24} md={24} lg={6} xl={6}>
      {editable && isEditableModeActive ? (
        <InfoUpdateForm
          fieldName={fieldName}
          value={value}
          label={label}
          toggleEditableMode={toggleEditableMode}
          setEditableMode={setEditableMode}
          placeholder={placeholder}
          ruleName={ruleName}
        />
      ) : (
        details
      )}
    </Col>
  );
};

const InfoUpdateForm = ({
  fieldName,
  value,
  label,
  toggleEditableMode,
  setEditableMode,
  placeholder,
  ruleName
}) => {
  const [form] = Form.useForm();
  const [updateApiStatus, updateProfile] = useUpdateProfile(setEditableMode);

  const handleSubmit = () => {
    form.validateFields().then(payload => {
      updateProfile(payload);
    });
  };

  return (
    <Form
      name={`edit-${fieldName}`}
      form={form}
      initialValues={{ [fieldName]: value }}
      className={styles.formView}
    >
      <div className={styles.formLabelView}>
        <h3 className={styles.label}>{`${label}:`}</h3>
        {updateApiStatus === API_STATUS.LOADING ? (
          <Spin
            indicator={<LoadingOutlined spin className={styles.spinner} />}
          />
        ) : (
          <>
            <CloseCircleOutlined
              className={styles.editIcon}
              onClick={toggleEditableMode(false)}
            />
            <CheckCircleOutlined
              className={styles.editIcon}
              onClick={handleSubmit}
            />
          </>
        )}
      </div>
      <Form.Item
        name={fieldName}
        rules={registrationRules[ruleName]}
        hasFeedback
      >
        <Input placeholder={placeholder} size="large" />
      </Form.Item>
    </Form>
  );
};

const AddressList = ({ addressList }) => {
  const [selectedAddress, setSelectedAddress] = useState(null);
  const dispatch = useDispatch();
  const [
    deleteAddressApiStatus,
    addressInDelete,
    deleteAddress
  ] = useDeleteAddress(setSelectedAddress);
  const addressCount = lodashGet(addressList, "length", 0);

  const openAddAddressModal = () => {
    dispatch(openModal({ modalType: "AddAddressModal" }));
  };

  const handleSelectedAddress = id => () => {
    setSelectedAddress(id);
  };

  const handleDelete = addressId => () => {
    if (addressInDelete) {
      errorNotification("Error", "Address deletion is already in progress.");
    } else {
      deleteAddress(addressId);
    }
  };

  return (
    <>
      <h2>
        Address List{" "}
        <PlusCircleOutlined
          className={styles.addAddressIcon}
          onClick={openAddAddressModal}
        />
      </h2>
      {addressList.map(address => (
        <>
          {selectedAddress === address.id ? (
            <AddressUpdateForm
              setSelectedAddress={handleSelectedAddress}
              address={address}
              key={address.id}
            />
          ) : (
            <Row key={address.id}>
              <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                <h3>Address:</h3>
                <p>{address.address_line}</p>
              </Col>
              <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                <h3>Pincode:</h3>
                <p>{address.pincode}</p>
              </Col>

              <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                <h3>City:</h3>
                <p>{address.city.name}</p>
              </Col>
              <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                <h3>State:</h3>
                <p>{address.city.state.name}</p>
              </Col>
              {API_STATUS.LOADING === deleteAddressApiStatus &&
              address.id === addressInDelete ? (
                <Spin
                  indicator={
                    <LoadingOutlined
                      spin
                      className={styles.addressDeleteIcon}
                    />
                  }
                />
              ) : (
                <>
                  <EditOutlined
                    className={styles.addressEditIcon}
                    onClick={handleSelectedAddress(address.id)}
                  />
                  {addressCount > 1 && (
                    <DeleteOutlined
                      className={styles.addressDeleteIcon}
                      onClick={handleDelete(address.id)}
                    />
                  )}
                </>
              )}
            </Row>
          )}
        </>
      ))}
    </>
  );
};

const AddressUpdateForm = ({ setSelectedAddress, address }) => {
  const [form] = Form.useForm();
  const [updateAddressApiStatus, updateAddress] = useUpdateAddress(
    setSelectedAddress
  );

  const handleSubmit = () => {
    form.validateFields().then(payload => {
      updateAddress(payload, address.id);
    });
  };

  return (
    <Form
      name={`edit-address`}
      form={form}
      initialValues={{
        address_line: address.address_line,
        pincode: address.pincode
      }}
      className={styles.formView}
    >
      <Row gutter={10}>
        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          <h3>Address:</h3>
          <Form.Item
            name="address_line"
            rules={registrationRules.addressLineRules}
            hasFeedback
          >
            <Input placeholder="Addresss" size="large" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          <h3>Pincode:</h3>
          <Form.Item
            name="pincode"
            rules={registrationRules.pincodeRules}
            hasFeedback
          >
            <Input placeholder="Pincode" size="large" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          <h3>City:</h3>
          <p>{address.city.name}</p>
        </Col>
        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          <h3>State:</h3>
          <p>{address.city.state.name}</p>
        </Col>
        {updateAddressApiStatus === API_STATUS.LOADING ? (
          <Spin
            indicator={
              <LoadingOutlined spin className={styles.addressDeleteIcon} />
            }
          />
        ) : (
          <>
            <CloseCircleOutlined
              className={styles.addressEditIcon}
              onClick={setSelectedAddress(null)}
            />
            <CheckCircleOutlined
              className={styles.addressDeleteIcon}
              onClick={handleSubmit}
            />
          </>
        )}
      </Row>
    </Form>
  );
};

export default Details;

const styles = {
  labelView: css`
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  `,
  label: css`
    margin-bottom: 0;
  `,
  editIcon: css`
    font-size: 18px;
    margin-left: 6px;
    margin-bottom: 3px;
    cursor: pointer;
    color: #1890ff;
  `,
  formView: css`
    margin-right: 20px;
  `,
  formLabelView: css`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  `,
  spinner: css`
    font-size: 18px;
    margin-left: 6px;
    margin-bottom: 3px;
  `,
  addressListContainer: css`
    margin-top: 15px;
  `,
  addressEditIcon: css`
    position: absolute;
    right: 9%;
    font-size: 20px;
    margin-top: 3px;
    cursor: pointer;
    color: #1890ff;
    @media (max-width: ${MOBILE_WIDTH}) {
      right: 15%;
    }
  `,
  addressDeleteIcon: css`
    position: absolute;
    right: 6%;
    font-size: 20px;
    margin-top: 3px;
    cursor: pointer;
    color: #1890ff;
  `,
  addAddressIcon: css`
    font-size: 20px;
    margin-top: 3px;
    cursor: pointer;
    color: #1890ff;
  `
};
