import { useState } from "react";
import to from "await-to-js";
import { API_STATUS } from "../../commons/constants";
import { updateOrder$$, getOrderDetails$$ } from "./services";

export const useGetOrderDetails = () => {
  const [apiStatus, setApiStatus] = useState(API_STATUS.IDLE);
  const [orderDetails, setOrderDetails] = useState(null);

  const getOrderDetails = async orderId => {
    setApiStatus(API_STATUS.LOADING);
    const [error, response] = await to(getOrderDetails$$(orderId));

    if (error) {
      setApiStatus(API_STATUS.REJECTED);
    } else {
      if (response.responseCode === 80) {
        setApiStatus(API_STATUS.RESOLVED);
        setOrderDetails(response.data);
      } else {
        setApiStatus(API_STATUS.REJECTED);
      }
    }
  };

  return [apiStatus, getOrderDetails, orderDetails];
};

export const useUpdateOrder = setEditableMode => {
  const [apiStatus, setApiStatus] = useState(API_STATUS.IDLE);

  const updateOrder = async (orderId, payload) => {
    setApiStatus(API_STATUS.LOADING);
    const [error, response] = await to(updateOrder$$(orderId, payload));

    if (error) {
      setApiStatus(API_STATUS.REJECTED);
    } else {
      if (response.responseCode === 80) {
        setApiStatus(API_STATUS.RESOLVED);
        setEditableMode(false);
        window.location.reload();
      } else {
        setApiStatus(API_STATUS.REJECTED);
      }
    }
  };

  return [apiStatus, updateOrder];
};
