import React, { useState } from "react";
import { Row, Col, Form, Input, Select } from "antd";
import {
  UserOutlined,
  MailOutlined,
  HomeOutlined,
  NumberOutlined
} from "@ant-design/icons";
import to from "await-to-js";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { regStyles as styles } from "./styles";
import ThemeButton from "../../commons/themeComponents/ThemeButton";
// import LogoList from "../../commons/commonComponents/LogoList";
import { stateList, API_STATUS, agentNameList } from "../../commons/constants";
import { registerUser$$ } from "./services";
import { setItem } from "../../commons/appUtils";
import { setUser } from "./authSlice";
import { registrationRules } from "./authValidations";
import MandatoryHeader from "../../commons/commonComponents/MandatoryHeader";
import { getRegistrationPayload } from "../../commons/payloadCreators";

const { Option } = Select;

const {
  firstNameRules,
  lastNameRules,
  agentNameRules,
  storeNameRules,
  emailRules,
  addressLineRules,
  cityRules,
  stateRules,
  pincodeRules,
  gstRules
} = registrationRules;

const useRegisterUser = history => {
  const [apiStatus, setApiStatus] = useState(API_STATUS.IDLE);
  const dispatch = useDispatch();

  const registerUser = async data => {
    setApiStatus(API_STATUS.LOADING);

    const payload = getRegistrationPayload(data);
    const [error, response] = await to(registerUser$$(payload));

    if (error) {
      setApiStatus(API_STATUS.REJECTED);
    } else {
      setApiStatus(API_STATUS.RESOLVED);
      if (response.responseCode === 80) {
        setItem("suprolStockUser", response.data);
        dispatch(setUser(response.data));
        history.replace("/");
      }
    }
  };

  return [apiStatus, registerUser];
};

const Registration = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [apiStatus, registerUser] = useRegisterUser(history);

  return (
    <div className={styles.container}>
      <div className={styles.innerView}>
        <div className={styles.logoList}>
          {/* <LogoList /> */}
        </div>
        <Row justify="center" align="middle">
          <Col xs={22} sm={22} md={22} lg={14} xl={14}>
            <div className={styles.headingView}>
              <h1 className={styles.heading}>Setup your profile</h1>
            </div>
            <Form name="registrationForm" form={form} onFinish={registerUser}>
              <p className={styles.formMessage}>
                NOTE: GST Number, Store Name & SUPROL Saled Manager, once chosen,
                can not be edited
              </p>
              <div className={styles.formView}>
                <Row gutter={12}>
                  <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                    <MandatoryHeader />
                    <Form.Item
                      name="first_name"
                      rules={firstNameRules}
                      hasFeedback
                    >
                      <Input
                        prefix={<UserOutlined />}
                        placeholder="First Name"
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                    <MandatoryHeader />
                    <Form.Item
                      name="last_name"
                      rules={lastNameRules}
                      hasFeedback
                    >
                      <Input
                        prefix={<UserOutlined />}
                        placeholder="Last Name"
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                    <MandatoryHeader />
                    <Form.Item
                      name="agent_name"
                      rules={agentNameRules}
                      hasFeedback
                    >
                      <Select
                        placeholder="SUPROL Sales Manager"
                        size="large"
                        showSearch={true}
                      >
                        {agentNameList.map(state => (
                          <Option value={state.value} key={state.value}>
                            {state.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                    <MandatoryHeader />
                    <Form.Item
                      name="store_name"
                      rules={storeNameRules}
                      hasFeedback
                    >
                      <Input
                        prefix={<HomeOutlined />}
                        placeholder="Store Name"
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                    <MandatoryHeader />
                    <Form.Item name="email" rules={emailRules} hasFeedback>
                      <Input
                        prefix={<MailOutlined />}
                        placeholder="Email"
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                    <MandatoryHeader />
                    <Form.Item
                      name={["address", "address_line"]}
                      rules={addressLineRules}
                      hasFeedback
                    >
                      <Input
                        prefix={<HomeOutlined />}
                        placeholder="Address"
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                    <MandatoryHeader />
                    <Form.Item
                      name={["address", "city"]}
                      rules={cityRules}
                      hasFeedback
                    >
                      <Input
                        prefix={<HomeOutlined />}
                        placeholder="City"
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                    <MandatoryHeader />
                    <Form.Item
                      name={["address", "state"]}
                      rules={stateRules}
                      hasFeedback
                    >
                      <Select
                        placeholder="Select State"
                        size="large"
                        showSearch={true}
                      >
                        {stateList.map(state => (
                          <Option value={state.value} key={state.value}>
                            {state.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                    <MandatoryHeader />
                    <Form.Item
                      name={["address", "pincode"]}
                      rules={pincodeRules}
                      hasFeedback
                    >
                      <Input
                        prefix={<NumberOutlined />}
                        placeholder="Pincode"
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                    <Form.Item name="gst_number" rules={gstRules} hasFeedback>
                      <Input
                        prefix={<UserOutlined />}
                        placeholder="GST Number"
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item>
                  <ThemeButton
                    size="large"
                    block
                    htmlType="submit"
                    text="Submit"
                    loading={apiStatus === API_STATUS.LOADING}
                  />
                </Form.Item>
              </div>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Registration;
