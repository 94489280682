import React, { useState } from "react";
import { Row, Col, Form, Input, Select, Checkbox } from "antd";
import { css } from "emotion";
import { useSelector } from "react-redux";
import to from "await-to-js";
import ThemeButton from "../../commons/themeComponents/ThemeButton";
import { getUserDataForOrdersPunching } from "./orderPunching.selectors";
import { API_STATUS, MOBILE_WIDTH } from "../../commons/constants";
import { punchOrder$$ } from "./services";
import Loader from "../../commons/commonComponents/Loader";
import { useParams } from "react-router-dom";
import { successNotification, errorNotification } from "../../commons/appUtils";
import { useInitiatePayment } from "../payment/paymentHooks";

const { Option } = Select;

const EYE_TYPES = {
  RIGHT: "RIGHT",
  LEFT: "LEFT",
};

const usePunchOrder = () => {
  const [punchingApiStatus, setApiStatus] = useState(API_STATUS.IDLE);
  const [
    initiatePaymentApiStatus,
    initiatePayment,
    paymentSuccessApiStatus,
    paymentFailureApiStatus,
  ] = useInitiatePayment();

  const punchOrder = async (payload, orderAmount) => {
    setApiStatus(API_STATUS.LOADING);
    const [error, response] = await to(punchOrder$$(payload));

    if (error) {
      setApiStatus(API_STATUS.REJECTED);
    } else {
      if (response.responseCode === 80) {
        setApiStatus(API_STATUS.RESOLVED);
        successNotification("Success", "Order punched successfully.");
        initiatePayment({
          amount_paise: String(Number(orderAmount) * 100),
          order_id: response.data.id,
        });
      } else {
        setApiStatus(API_STATUS.REJECTED);
      }
    }
  };

  return [
    punchingApiStatus,
    punchOrder,
    initiatePaymentApiStatus,
    paymentSuccessApiStatus,
    paymentFailureApiStatus,
  ];
};

const PunchingForm = ({ couponData }) => {
  const [isRightEyeActive, setRightEyeActive] = useState(false);
  const [isLeftEyeActive, setLeftEyeActive] = useState(false);

  const [form] = Form.useForm();
  const { couponId } = useParams();

  const userData = useSelector(getUserDataForOrdersPunching);
  const [
    punchingApiStatus,
    punchOrder,
    initiatePaymentApiStatus,
    paymentSuccessApiStatus,
    paymentFailureApiStatus,
  ] = usePunchOrder();

  const setEyeActiveStatus = (type) => () => {
    type === EYE_TYPES.RIGHT && setRightEyeActive(!isRightEyeActive);
    type === EYE_TYPES.LEFT && setLeftEyeActive(!isLeftEyeActive);
  };

  const handleSubmit = (values) => {
    // console.log(values);
    // const { rightEyeEnabled, leftEyeEnabled } = values;
    // if (!rightEyeEnabled && !leftEyeEnabled) {
    //   errorNotification("Error", "Please select atleast one eye");
    // } else {
    //   let payload = {
    //     coupon_code: couponId,
    //     shipping_address: values.shipping_address,
    //   };
    //   if (rightEyeEnabled && leftEyeEnabled) {
    //     payload = {
    //       ...payload,
    //       left_lens_config: {
    //         ...values.left_lens_config,
    //         dia: "65",
    //       },
    //       right_lens_config: {
    //         ...values.right_lens_config,
    //         dia: "65",
    //       },
    //     };
    //   }
    //   if (rightEyeEnabled && !leftEyeEnabled) {
    //     payload = {
    //       ...payload,
    //       right_lens_config: {
    //         ...values.right_lens_config,
    //         dia: "65",
    //       },
    //     };
    //   } else if (leftEyeEnabled && !rightEyeEnabled) {
    //     payload = {
    //       ...payload,
    //       left_lens_config: {
    //         ...values.left_lens_config,
    //         dia: "65",
    //       },
    //     };
    //   }
    const payload = {
      coupon_code: couponId,
      shipping_address: values.shipping_address,
      left_lens_config: {
        sph: "0.0",
        cyl: "0.0",
        dia: "65",
      },
      right_lens_config: {
        sph: "0.0",
        cyl: "0.0",
        dia: "65",
      },
    };
    punchOrder(payload, couponData.orderAmount);
  };

  if (
    punchingApiStatus === API_STATUS.LOADING ||
    initiatePaymentApiStatus === API_STATUS.LOADING ||
    paymentSuccessApiStatus === API_STATUS.LOADING ||
    paymentFailureApiStatus === API_STATUS.LOADING
  )
    return <Loader />;

  return (
    <div className={styles.container}>
      <Form
        name="orderPunchingForm"
        form={form}
        onFinish={handleSubmit}
        initialValues={{
          rightEyeEnabled: isRightEyeActive,
          leftEyeEnabled: isLeftEyeActive,
        }}
      >
        <Row>
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <h3>Product Description:</h3>
            <p>{couponData.lnamDescription}</p>
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <h3>Coating:</h3>
            <p>{couponData.coating}</p>
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <h3>Store Name:</h3>
            <p>{userData.storeName}</p>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <h3>Shipping Address:</h3>
            <Form.Item
              name="shipping_address"
              rules={[{ required: true, message: "Please select Address!" }]}
              hasFeedback
            >
              <Select
                placeholder="Select Address"
                size="large"
                showSearch={true}
              >
                {userData.addressList.map((adr) => (
                  <Option value={adr.value} key={adr.value}>
                    {adr.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <h3>Please enter your prescription:</h3>
        {/* <Row gutter={30}>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            className={styles.rightEyeCol}
          >
            <Row gutter={15}>
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <Form.Item name="rightEyeEnabled" valuePropName="checked">
                  <Checkbox onChange={setEyeActiveStatus(EYE_TYPES.RIGHT)}>
                    Enable Right Eye:
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                <Form.Item
                  name={["right_lens_config", "sph"]}
                  rules={[
                    {
                      required: isRightEyeActive,
                      message: "Please input Sph!",
                    },
                    () => ({
                      validator(rule, value) {
                        if (!value) return Promise.resolve();
                        const sph = Number(value);
                        if (!isNaN(sph) && typeof sph === "number") {
                          return Promise.resolve();
                        } else {
                          return Promise.reject("Please input Number!");
                        }
                      },
                    }),
                    {
                      validator: (rule, value) =>
                        !value || (value > -7 && value < 5)
                          ? Promise.resolve()
                          : Promise.reject(
                              "Enter prescription value b/w -6 and +4"
                            ),
                    },
                    {
                      validator: (rule, value) => {
                        const remainder = value % 0.25;
                        if (value && remainder !== 0) {
                          return Promise.reject(
                            "Only numbers in 0.25 increments allowed"
                          );
                        } else {
                          return Promise.resolve();
                        }
                      },
                    },
                  ]}
                  hasFeedback
                >
                  <Input
                    placeholder="Sph"
                    size="large"
                    disabled={!isRightEyeActive}
                  />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                <Form.Item
                  name={["right_lens_config", "cyl"]}
                  rules={[
                    {
                      required: isRightEyeActive,
                      message: "Please input Cyl!",
                    },
                    () => ({
                      validator(rule, value) {
                        if (!value) return Promise.resolve();
                        const cyl = Number(value);
                        if (!isNaN(cyl) && typeof cyl === "number") {
                          return Promise.resolve();
                        } else {
                          return Promise.reject("Please input Number!");
                        }
                      },
                    }),
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        const sph = Number(
                          getFieldValue(["right_lens_config", "sph"])
                        );
                        const cyl = Number(value);
                        if (!cyl) return Promise.resolve();
                        if (sph > -7 && sph < 1) {
                          if (cyl > -5 && cyl < 1) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject(
                              "Enter prescription value b/w -4 & 0"
                            );
                          }
                        } else if (sph > 0 && sph < 5) {
                          if (cyl > 0 && cyl < 3) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject(
                              "Enter prescription value b/w 0 & 2"
                            );
                          }
                        } else {
                          return Promise.reject("Please input correct Sph!");
                        }
                      },
                    }),
                    {
                      validator: (rule, value) => {
                        const remainder = value % 0.25;
                        if (value && remainder !== 0) {
                          return Promise.reject(
                            "Only numbers in 0.25 increments allowed"
                          );
                        } else {
                          return Promise.resolve();
                        }
                      },
                    },
                  ]}
                  hasFeedback
                >
                  <Input
                    placeholder="Cyl"
                    size="large"
                    disabled={!isRightEyeActive}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            className={styles.leftEyeCol}
          >
            <Row gutter={15}>
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <Form.Item name="leftEyeEnabled" valuePropName="checked">
                  <Checkbox onChange={setEyeActiveStatus(EYE_TYPES.LEFT)}>
                    Enable Left Eye:
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                <Form.Item
                  name={["left_lens_config", "sph"]}
                  rules={[
                    {
                      required: isLeftEyeActive,
                      message: "Please input Sph!",
                    },
                    () => ({
                      validator(rule, value) {
                        if (!value) return Promise.resolve();
                        const sph = Number(value);
                        if (!isNaN(sph) && typeof sph === "number") {
                          return Promise.resolve();
                        } else {
                          return Promise.reject("Please input Number!");
                        }
                      },
                    }),
                    {
                      validator: (rule, value) =>
                        !value || (value > -7 && value < 5)
                          ? Promise.resolve()
                          : Promise.reject(
                              "Enter prescription value b/w -6 and +4"
                            ),
                    },
                    {
                      validator: (rule, value) => {
                        const remainder = value % 0.25;
                        if (value && remainder !== 0) {
                          return Promise.reject(
                            "Only numbers in 0.25 increments allowed"
                          );
                        } else {
                          return Promise.resolve();
                        }
                      },
                    },
                  ]}
                  hasFeedback
                >
                  <Input
                    placeholder="Sph"
                    size="large"
                    disabled={!isLeftEyeActive}
                  />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                <Form.Item
                  name={["left_lens_config", "cyl"]}
                  rules={[
                    {
                      required: isLeftEyeActive,
                      message: "Please input Cyl!",
                    },
                    () => ({
                      validator(rule, value) {
                        if (!value) return Promise.resolve();
                        const cyl = Number(value);
                        if (!isNaN(cyl) && typeof cyl === "number") {
                          return Promise.resolve();
                        } else {
                          return Promise.reject("Please input Number!");
                        }
                      },
                    }),
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        const sph = Number(
                          getFieldValue(["left_lens_config", "sph"])
                        );
                        const cyl = Number(value);
                        if (!cyl) return Promise.resolve();
                        if (sph > -7 && sph < 1) {
                          if (cyl > -5 && cyl < 1) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject(
                              "Enter prescription value b/w -4 & 0"
                            );
                          }
                        } else if (sph > 0 && sph < 5) {
                          if (cyl > 0 && cyl < 3) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject(
                              "Enter prescription value b/w 0 & 2"
                            );
                          }
                        } else {
                          return Promise.reject("Please input correct Sph!");
                        }
                      },
                    }),
                    {
                      validator: (rule, value) => {
                        const remainder = value % 0.25;
                        if (value && remainder !== 0) {
                          return Promise.reject(
                            "Only numbers in 0.25 increments allowed"
                          );
                        } else {
                          return Promise.resolve();
                        }
                      },
                    },
                  ]}
                  hasFeedback
                >
                  <Input
                    placeholder="Cyl"
                    size="large"
                    disabled={!isLeftEyeActive}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row> */}

        {/* <Form.Item>
          <div className={styles.btnView}>
            <ThemeButton size="large" htmlType="submit" text="Punch Order" />
          </div>
        </Form.Item> */}
        <Row gutter={30} style={{ marginBottom: 20 }}>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            className={styles.rightEyeCol}
          >
            <Row gutter={15}>
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                SPH: 0.0
              </Col>
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                CYL: 0.0
              </Col>
            </Row>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            className={styles.leftEyeCol}
          >
            <Row gutter={15}>
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                SPH: 0.0
              </Col>
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                CYL: 0.0
              </Col>
            </Row>
          </Col>
        </Row>
        <div className={styles.btnView}>
          <ThemeButton size="large" htmlType="submit" text="Punch Order" />
        </div>
      </Form>
      <ul>
        {/* <li>
          Please enter prescription between the following Prescription range:
          -6/-4; +3/+2
        </li> */}
        <li>
          Depending on your location, your order will typically be delivered
          between 3-7 working days.
        </li>
        <li>
          In case of any queries or feedback, please give a missed call on +91
          8800225997 and we will call you back within 15 minutes.
        </li>
        <li>
          Alternatively you can also email us at optical.dhamaka@suprol.in.
        </li>
      </ul>
    </div>
  );
};

export default PunchingForm;

const styles = {
  container: css`
    width: 90%;
    margin-left: 8%;
    margin-top: 3%;
    @media (max-width: ${MOBILE_WIDTH}) {
      margin: auto;
      padding-top: 20px;
    }
  `,
  rightEyeCol: css`
    padding-top: 20px;
    background-color: #faebd7;
  `,
  leftEyeCol: css`
    padding-top: 20px;
    background-color: #f0f7fe;
  `,
  btnView: css`
    margin-top: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
};
