import { css } from "emotion";

const regStyles = {
  container: css`
    align-items: center;
    justify-content: center;
    display: flex;
  `,
  innerView: css`
    width: 100%;
  `,
  headingView: css`
    justify-content: center;
    align-items: center;
    display: flex;
    margin-bottom: 1em;
  `,
  heading: css`
    margin: 0;
    color: #4D33A1;
  `,
  formView: css`
    padding: 7%;
    background: white;
    box-shadow: 0 1px 8px 0 rgba(34, 36, 38, 0.15);
  `,
  logoList: css`
    margin: 2%;
  `,
  formMessage: css`
    text-align: center;
    color: red;
    font-weight: bold;
  `
};

export { regStyles };
