import { useState } from "react";
import to from "await-to-js";
import { useDispatch } from "react-redux";
import { updateProfile$$, updateAddress$$, deleteAddress$$ } from "./services";
import { setItem } from "../../commons/appUtils";
import { setUser } from "../auth/authSlice";
import { API_STATUS } from "../../commons/constants";

export const useUpdateProfile = setEditableMode => {
  const [apiStatus, setApiStatus] = useState(API_STATUS.IDLE);
  const dispatch = useDispatch();

  const updateProfile = async payload => {
    setApiStatus(API_STATUS.LOADING);
    const [error, response] = await to(updateProfile$$(payload));

    if (error) {
      setApiStatus(API_STATUS.REJECTED);
    } else {
      if (response.responseCode === 80) {
        setApiStatus(API_STATUS.RESOLVED);
        setEditableMode(false);
        setItem("suprolStockUser", response.data);
        dispatch(setUser(response.data));
      } else {
        setApiStatus(API_STATUS.REJECTED);
      }
    }
  };

  return [apiStatus, updateProfile];
};

export const useAddAddress = closeModal => {
  const [apiStatus, setApiStatus] = useState(API_STATUS.IDLE);
  const dispatch = useDispatch();

  const addAddress = async payload => {
    setApiStatus(API_STATUS.LOADING);
    const [error, response] = await to(updateProfile$$(payload));

    if (error) {
      setApiStatus(API_STATUS.REJECTED);
    } else {
      if (response.responseCode === 80) {
        setApiStatus(API_STATUS.RESOLVED);
        setItem("suprolStockUser", response.data);
        dispatch(setUser(response.data));
        closeModal();
      } else {
        setApiStatus(API_STATUS.REJECTED);
      }
    }
  };

  return [apiStatus, addAddress];
};

export const useUpdateAddress = setSelectedAddress => {
  const [apiStatus, setApiStatus] = useState(API_STATUS.IDLE);
  const dispatch = useDispatch();

  const updateAddress = async (payload, addressId) => {
    setApiStatus(API_STATUS.LOADING);
    const [error, response] = await to(updateAddress$$(payload, addressId));

    if (error) {
      setApiStatus(API_STATUS.REJECTED);
    } else {
      if (response.responseCode === 80) {
        setApiStatus(API_STATUS.RESOLVED);
        setSelectedAddress(null)();
        setItem("suprolStockUser", response.data);
        dispatch(setUser(response.data));
      } else {
        setApiStatus(API_STATUS.REJECTED);
      }
    }
  };

  return [apiStatus, updateAddress];
};

export const useDeleteAddress = setSelectedAddress => {
  const [apiStatus, setApiStatus] = useState(API_STATUS.IDLE);
  const [addressInDelete, setDeleteAddress] = useState(null);
  const dispatch = useDispatch();

  const deleteAddress = async addressId => {
    setDeleteAddress(addressId);
    setApiStatus(API_STATUS.LOADING);
    const [error, response] = await to(deleteAddress$$(addressId));

    if (error) {
      setDeleteAddress(null);
      setApiStatus(API_STATUS.REJECTED);
    } else {
      if (response.responseCode === 80) {
        setApiStatus(API_STATUS.RESOLVED);
        setSelectedAddress(null);
        setItem("suprolStockUser", response.data);
        dispatch(setUser(response.data));
        setDeleteAddress(null);
      } else {
        setApiStatus(API_STATUS.REJECTED);
        setDeleteAddress(null);
      }
    }
  };

  return [apiStatus, addressInDelete, deleteAddress];
};
