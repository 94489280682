import React from "react";
import { Provider } from "react-redux";
import store from "./commons/store/configureStore";
import AppRouter from "./commons/layout/AppRouter";

const App = () => {
  return (
    <Provider store={store}>
      <AppRouter />
    </Provider>
  );
};

export default App;
