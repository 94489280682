import { get as lodashGet } from "lodash";
import { createSelector } from "@reduxjs/toolkit";
import { getCurrentUser } from "../user/user.selectors";

const createDataForOrderPunching = data => {
  let addressList = lodashGet(data, "address", []);

  addressList = addressList.map(adr => ({
    label: `${adr.address_line}, ${adr.city.name}, ${adr.city.state.name}, ${adr.pincode}`,
    value: adr.id
  }));

  return {
    addressList,
    storeName: lodashGet(data, "store_name", "-")
  };
};

export const getUserDataForOrdersPunching = createSelector(
  getCurrentUser,
  createDataForOrderPunching
);
