import axios from "axios";
import { get as lodashGet } from "lodash";
import { errorNotification } from "./appUtils";
// import { history } from "./layout/AppRouter";

const successHandler = async response => {
  console.log(response);
  const responseCode = lodashGet(response, "data.response_code", null);
  const uiMessage = lodashGet(response, "data.ui_message", "");
  const data = lodashGet(response, "data.data", null);
  if (responseCode === 30) {
    // Write logic for token expire and redirect to login
  } else if (responseCode === 90) {
    errorNotification("Error", uiMessage);
  }
  return {
    responseCode,
    uiMessage,
    data
  };
};

const errorHandler = error => {
  // const responseCode = lodashGet(error, "response.status", null);
  // if (responseCode === 401) history.push("/login");
  errorNotification("Error", error.message);
  return Promise.reject(error);
};

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error)
);

export { axiosInstance };
