import React from "react";
import { Button } from "antd";
import { css } from "emotion";

const ThemeButton = ({ text, ...otherProps }) => (
  <Button className={styles.btn} {...otherProps}>
    {text}
  </Button>
);

export default ThemeButton;

const styles = {
  btn: css`
    background: #4D33A1;
    border-color: #4D33A1;
    color: white;
    &:hover,
    &:focus {
      border-color: #4D33A1;
      background: #4D33A1;
      opacity: 0.8;
      color: white;
    }
  `
};
