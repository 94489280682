import React from "react";
import Sidebar from "../../commons/commonComponents/Sidebar";
import Navbar from "../../commons/commonComponents/Navbar";
import Details from "./Details";
import { css } from "emotion";
import { useSelector } from "react-redux";
import { getCurrentUser } from "./user.selectors";
import { MOBILE_WIDTH } from "../../commons/constants";

const UserDetails = () => {
  const user = useSelector(getCurrentUser);
  return (
    <div>
      <Sidebar />
      <Navbar />
      <div className={styles.detailsView}>
        {user && <Details user={user} />}
      </div>
    </div>
  );
};

export default UserDetails;

const styles = {
  detailsView: css`
    width: 85%;
    margin-left: 10%;
    margin-top: 2%;
    @media (max-width: ${MOBILE_WIDTH}) {
      margin-left: 4%;
    }
  `
};
