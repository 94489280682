import { notification } from "antd";
import { get as lodashGet } from "lodash";

export const makeURL = endpoint => `https://api.suprol.club${endpoint}`;

export const getToken = () => ({
  headers: {
    Authorization: `Token ${lodashGet(
      getItem("suprolStockUser"),
      "auth_token",
      null
    )}`
  }
});

export const setItem = (key, value) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

export const getItem = key => {
  const item = window.localStorage.getItem(key);
  const parsedItem = JSON.parse(item);
  return parsedItem;
};

export const removeItem = key => {
  window.localStorage.removeItem(key);
};

export const errorNotification = (message, description, duration) => {
  notification.error({
    message,
    description,
    duration: duration ? duration : 5
  });
};

export const successNotification = (message, description, duration) => {
  notification.success({
    message,
    description,
    duration: duration ? duration : 2
  });
};
