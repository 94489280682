import { axiosInstance } from "../../commons/axiosConfig";
import { makeURL, getToken } from "../../commons/appUtils";
import { SERVICE_METHODS } from "../../commons/constants";

export const checkCouponValidity$$ = coupon => {
  return axiosInstance({
    method: SERVICE_METHODS.GET,
    url: makeURL(`/coupon/details/${coupon}`),
    ...getToken()
  });
};

export const punchOrder$$ = data => {
  return axiosInstance({
    method: SERVICE_METHODS.POST,
    url: makeURL(`/coupon/create/order`),
    data,
    ...getToken()
  });
};
