import React, { useState, useEffect } from "react";
import { css } from "emotion";
import to from "await-to-js";
import Sidebar from "../../commons/commonComponents/Sidebar";
import Navbar from "../../commons/commonComponents/Navbar";
import ThemeButton from "../../commons/themeComponents/ThemeButton";
import OrderListTable from "./OrderListTable";
import { useDispatch } from "react-redux";
import { openModal } from "../modals/ModalSlice";
import { API_STATUS } from "../../commons/constants";
import { getOrderList$$ } from "./services";

const useGetOrderList = () => {
  const [apiStatus, setApiStatus] = useState(API_STATUS.IDLE);
  const [orderListData, setOrderListData] = useState({});

  const getOrderList = async page => {
    setApiStatus(API_STATUS.LOADING);
    const [error, response] = await to(getOrderList$$(page));

    if (error) {
      setApiStatus(API_STATUS.REJECTED);
    } else {
      if (response.responseCode === 80) {
        setApiStatus(API_STATUS.RESOLVED);
        setOrderListData(response.data);
      } else {
        setApiStatus(API_STATUS.REJECTED);
      }
    }
  };

  return [apiStatus, orderListData, getOrderList];
};

const OrderListing = () => {
  const dispatch = useDispatch();
  const [orderListApiStatus, orderListData, getOrderList] = useGetOrderList();

  const openCouponModal = () => {
    dispatch(openModal({ modalType: "ApplyCouponModal" }));
  };

  useEffect(() => {
    getOrderList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Sidebar />
      <Navbar />
      <div className={styles.btnView}>
        <ThemeButton
          type="primary"
          size="large"
          text="Use Coupon"
          onClick={openCouponModal}
        />
      </div>
      <div className={styles.tableView}>
        <OrderListTable
          loading={orderListApiStatus === API_STATUS.LOADING}
          data={orderListData}
          getOrderList={getOrderList}
        />
      </div>
    </div>
  );
};

export default OrderListing;

const styles = {
  btnView: css`
    display: flex;
    justify-content: flex-end;
    margin: 3% 4%;
  `,
  tableView: css`
    width: 85%;
    margin-left: 10%;
  `
};
