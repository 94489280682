import React, { useEffect } from "react";
import Sidebar from "../../commons/commonComponents/Sidebar";
import Navbar from "../../commons/commonComponents/Navbar";
import { useParams } from "react-router-dom";
import { API_STATUS } from "../../commons/constants";
import Loader from "../../commons/commonComponents/Loader";
import ErrorMessage from "../../commons/commonComponents/ErrorMessage";
import OrderDetailView from "./OrderDetailView";
import { useOrderDetailsModelCreator } from "../../commons/modelCreators";
import { getCurrentUser } from "../user/user.selectors";
import { useSelector } from "react-redux";
import { useGetOrderDetails } from "./detailHooks";
import { useInitiatePayment } from "../payment/paymentHooks";

const OrderDetails = () => {
  const { orderId } = useParams();
  const [
    detailsApiStatus,
    getOrderDetails,
    orderDetails,
  ] = useGetOrderDetails();

  const orderDetailsModeledData = useOrderDetailsModelCreator(orderDetails);
  const user = useSelector(getCurrentUser);

  const [
    initiatePaymentApiStatus,
    initiatePayment,
    paymentSuccessApiStatus,
    paymentFailureApiStatus,
  ] = useInitiatePayment();

  useEffect(() => {
    getOrderDetails(orderId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Sidebar />
      <Navbar />
      {(detailsApiStatus === API_STATUS.LOADING ||
        detailsApiStatus === API_STATUS.IDLE ||
        initiatePaymentApiStatus === API_STATUS.LOADING ||
        paymentSuccessApiStatus === API_STATUS.LOADING ||
        paymentFailureApiStatus === API_STATUS.LOADING) && <Loader />}

      {detailsApiStatus === API_STATUS.REJECTED &&
        initiatePaymentApiStatus === API_STATUS.REJECTED &&
        paymentSuccessApiStatus === API_STATUS.REJECTED &&
        paymentFailureApiStatus === API_STATUS.REJECTED && <ErrorMessage />}

      {detailsApiStatus === API_STATUS.RESOLVED &&
        initiatePaymentApiStatus === API_STATUS.IDLE &&
        paymentSuccessApiStatus === API_STATUS.IDLE &&
        paymentFailureApiStatus === API_STATUS.IDLE &&
        user && (
          <OrderDetailView
            details={orderDetailsModeledData}
            user={user}
            initiatePayment={initiatePayment}
          />
        )}
    </div>
  );
};

export default OrderDetails;
