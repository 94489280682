import { get as lodashGet } from "lodash";
import moment from "moment";

export const useCouponDataModelCreator = (data) => {
  return {
    lnamDescription: lodashGet(data, "lnam.description", "N/A"),
    coating: lodashGet(data, "coating.description", "N/A"),
    orderAmount: lodashGet(data, "cost", 0),
  };
};

const getAddress = (address) => {
  const city = lodashGet(address, "city.name", null);
  const state = lodashGet(address, "city.state.name", null);
  const addressline = lodashGet(address, "address_line", null);
  const pincode = lodashGet(address, "pincode", null);

  const completeAddress = `${addressline ? `${addressline}, ` : ""}${
    city ? `${city}, ` : ""
  }${state ? `${state}, ` : ""}${pincode ? `${pincode}` : ""}`;

  return completeAddress;
};

export const useOrderDetailsModelCreator = (data) => {
  return {
    orderId: lodashGet(data, "internal_order_id", "N/A"),
    createdOn: lodashGet(data, "created_on", null)
      ? moment(data.created_on).format("YYYY-MM-DD hh:mm a")
      : "N/A",
    status: lodashGet(data, "stock_status", "N/A"),
    rightSph: lodashGet(data, "right_lens_config.sph", "N/A"),
    rightCyl: lodashGet(data, "right_lens_config.cyl", "N/A"),
    leftSph: lodashGet(data, "left_lens_config.sph", "N/A"),
    leftCyl: lodashGet(data, "left_lens_config.cyl", "N/A"),
    lnamDescription: lodashGet(data, "lnam.description", "N/A"),
    coatingDescription: lodashGet(data, "coating.description", "N/A"),
    address: lodashGet(data, "shipping_address", null)
      ? getAddress(data.shipping_address)
      : "N/A",
    isPaid: lodashGet(data, "is_paid", true),
    eligibleForPayment: lodashGet(data, "eligible_for_payment", false),
    cost: String(Number(lodashGet(data, "cost", 0)) * 100),
    id: lodashGet(data, "id", null),
  };
};
