import React, { useState, useCallback } from "react";
import { Row, Col, Form, Select, Spin, Tag } from "antd";
import { css } from "emotion";
import {
  EditOutlined,
  LoadingOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { API_STATUS, statusList } from "../../commons/constants";
import { useUpdateOrder } from "./detailHooks";
import ThemeButton from "../../commons/themeComponents/ThemeButton";

const { Option } = Select;

const OrderDetailView = ({ details, user, initiatePayment }) => {
  const handleInitiatePayment = useCallback(() => {
    const payload = {
      order_id: details.id,
      amount_paise: details.cost,
    };
    initiatePayment(payload);
  }, [details, initiatePayment]);

  return (
    <div className={styles.container}>
      <Row gutter={10}>
        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <h3>Order Id:</h3>
          <p>{details.orderId}</p>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <h3>Distributor:</h3>
          <p>{user.distributor}</p>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <h3>Shipping Address:</h3>
          <p>{details.address}</p>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <h3>Lnam Description:</h3>
          <p>{details.lnamDescription}</p>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <h3>Coating Description:</h3>
          <p>{details.coatingDescription}</p>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <h3>Created On:</h3>
          <p>{details.createdOn}</p>
        </Col>
        <InfoItem
          editable={user.master_account}
          label="Status"
          value={details.status}
          fieldName="stock_status"
          placeholder="Status"
        />
        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <h3>Payment Status:</h3>
          <p>
            {details.isPaid ? (
              <Tag icon={<CheckCircleOutlined />} color="success">
                Successful
              </Tag>
            ) : (
              <Tag icon={<CloseCircleOutlined />} color="error">
                Failed
              </Tag>
            )}
          </p>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <h3>Right Sph:</h3>
          <p>{details.rightSph}</p>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <h3>Right Cyl:</h3>
          <p>{details.rightCyl}</p>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <h3>Left Sph:</h3>
          <p>{details.leftSph}</p>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <h3>Left Cyl:</h3>
          <p>{details.leftCyl}</p>
        </Col>
        {!details.isPaid && details.eligibleForPayment && (
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <ThemeButton
              size="medium"
              text="Retry Payment"
              onClick={handleInitiatePayment}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

const InfoItem = ({ editable, label, value, fieldName, placeholder }) => {
  const [isEditableModeActive, setEditableMode] = useState(false);

  const toggleEditableMode = (newValue) => () => {
    setEditableMode(newValue);
  };

  const details = (
    <>
      <div className={styles.labelView}>
        <h3 className={styles.label}>{`${label}:`}</h3>
        {editable && (
          <EditOutlined
            className={styles.editIcon}
            onClick={toggleEditableMode(true)}
          />
        )}
      </div>
      <p>{value}</p>
    </>
  );

  return (
    <Col xs={24} sm={24} md={24} lg={6} xl={6}>
      {editable && isEditableModeActive ? (
        <InfoUpdateForm
          fieldName={fieldName}
          value={value}
          label={label}
          toggleEditableMode={toggleEditableMode}
          setEditableMode={setEditableMode}
          placeholder={placeholder}
        />
      ) : (
        details
      )}
    </Col>
  );
};

const InfoUpdateForm = ({
  fieldName,
  value,
  label,
  toggleEditableMode,
  setEditableMode,
  placeholder,
}) => {
  const [form] = Form.useForm();
  const [updateApiStatus, updateProfile] = useUpdateOrder(setEditableMode);
  const { orderId } = useParams();

  const handleSubmit = () => {
    form.validateFields().then((payload) => {
      updateProfile(orderId, payload);
    });
  };

  return (
    <Form
      name={`edit-${fieldName}`}
      form={form}
      initialValues={{ [fieldName]: value }}
      className={styles.formView}
    >
      <div className={styles.formLabelView}>
        <h3 className={styles.label}>{`${label}:`}</h3>
        {updateApiStatus === API_STATUS.LOADING ? (
          <Spin
            indicator={<LoadingOutlined spin className={styles.spinner} />}
          />
        ) : (
          <>
            <CloseCircleOutlined
              className={styles.editIcon}
              onClick={toggleEditableMode(false)}
            />
            <CheckCircleOutlined
              className={styles.editIcon}
              onClick={handleSubmit}
            />
          </>
        )}
      </div>
      <Form.Item
        name={fieldName}
        rules={[
          {
            required: true,
            message: `Please select status`,
          },
        ]}
        hasFeedback
      >
        <Select placeholder={placeholder} size="large" showSearch={true}>
          {statusList.map((state) => (
            <Option value={state.value} key={state.value}>
              {state.label}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  );
};

export default OrderDetailView;

const styles = {
  container: css`
    width: 90%;
    margin-left: 8%;
    margin-top: 3%;
  `,
  labelView: css`
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  `,
  label: css`
    margin-bottom: 0;
  `,
  editIcon: css`
    font-size: 18px;
    margin-left: 6px;
    margin-bottom: 3px;
    cursor: pointer;
    color: #1890ff;
  `,
  formView: css`
    margin-right: 20px;
  `,
  formLabelView: css`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  `,
  spinner: css`
    font-size: 18px;
    margin-left: 6px;
    margin-bottom: 3px;
  `,
};
