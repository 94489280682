import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { get as lodashGet } from "lodash";
import { getItem } from "../appUtils";
import { useDispatch } from "react-redux";
import { setUser } from "../../features/auth/authSlice";

/**
 * @param children
 * if auth token exist then check if the user is registered or not
 * if the user is registered then redirect him to dashboard and
 * if not registed then redirect to login
 */
const NotRegisteredRoute = ({ children, ...rest }) => {
  const dispatch = useDispatch();
  const user = getItem("suprolStockUser");
  const authToken = lodashGet(user, "auth_token", null);
  const isRegistered = lodashGet(user, "is_registered", false);

  useEffect(() => {
    authToken && isRegistered && dispatch(setUser(user));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (authToken && !isRegistered) {
          return children;
        } else if (authToken && isRegistered) {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: { from: location }
              }}
            />
          );
        } else if (!authToken) {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          );
        }
      }}
    />
  );
};

export default NotRegisteredRoute;
