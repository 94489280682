import React from "react";
import { Table, Tag } from "antd";
import moment from "moment";
import { css } from "emotion";
import { Link } from "react-router-dom";
import { MOBILE_WIDTH } from "../../commons/constants";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

const OrderListTable = ({ loading, data, getOrderList }) => {
  return (
    <div>
      <Table
        className={styles.table}
        loading={loading}
        columns={columns}
        dataSource={data.results || []}
        bordered
        size="small"
        rowKey={(record) => record.internal_order_id}
        onChange={(pagination) => getOrderList(pagination.current)}
        pagination={{
          position: ["bottomCenter"],
          size: "default",
          defaultPageSize: 5,
          defaultCurrent: 1,
          total: data.count,
        }}
      />
    </div>
  );
};

export default OrderListTable;

const columns = [
  {
    title: "Order Id",
    dataIndex: "internal_order_id",
    render: (text) => <Link to={`/orderDetails/${text}`}>{text}</Link>,
  },
  {
    title: "Right Sph",
    dataIndex: ["right_lens_config", "sph"],
  },
  {
    title: "Right Cyl",
    dataIndex: ["right_lens_config", "cyl"],
  },
  {
    title: "Left Sph",
    dataIndex: ["left_lens_config", "sph"],
  },
  {
    title: "Left Cyl",
    dataIndex: ["left_lens_config", "cyl"],
  },
  {
    title: "Status",
    dataIndex: "stock_status",
  },
  {
    title: "Payment",
    dataIndex: "is_paid",
    render: (text, record) => {
      if (record.is_paid) {
        return (
          <Tag icon={<CheckCircleOutlined />} color="success">
            Successful
          </Tag>
        );
      } else {
        return (
          <Tag icon={<CloseCircleOutlined />} color="error">
            Failed
          </Tag>
        );
      }
    },
  },
  {
    title: "Created On",
    dataIndex: "created_on",
    render: (text) => {
      if (text !== null) {
        return <p>{moment(text).format("YYYY-MM-DD hh:mm a")}</p>;
      }
    },
  },
  {
    title: "Status Updated On",
    dataIndex: "updated_on",
    render: (text) => {
      if (text !== null) {
        return <p>{moment(text).format("YYYY-MM-DD hh:mm a")}</p>;
      }
    },
  },
];

const styles = {
  table: css`
    @media (max-width: ${MOBILE_WIDTH}) {
      overflow-y: scroll;
      width: 100%;
    }
  `,
};
