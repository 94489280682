import { axiosInstance } from "../../commons/axiosConfig";
import { SERVICE_METHODS } from "../../commons/constants";
import { makeURL, getToken } from "../../commons/appUtils";

export const initiatePayment$$ = (data) => {
  return axiosInstance({
    method: SERVICE_METHODS.POST,
    url: makeURL(`/payments/live/initiate/`),
    data,
    ...getToken(),
  });
};

export const reportPaymentSuccess$$ = (data, paymentId) => {
  return axiosInstance({
    method: SERVICE_METHODS.PUT,
    url: makeURL(`/payments/live/validate/${paymentId}`),
    data,
    ...getToken(),
  });
};

export const reportPaymentFailure$$ = (paymentId) => {
  return axiosInstance({
    method: SERVICE_METHODS.PUT,
    url: makeURL(`/payments/live/failure/${paymentId}`),
    data: { failure_description: "User cancelled the payment." },
    ...getToken(),
  });
};
