import React from "react";
import { Spin } from "antd";
import { css } from "emotion";

const Loader = () => (
  <div className={styles.container}>
    <div className={styles.innerView}>
      <Spin size="large" />
      <p className={styles.text}>Loading! Please Wait...</p>
    </div>
  </div>
);

export default Loader;

const styles = {
  container: css`
    width: 100%;
    position: absolute;
    top: 42%;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  innerView: css`
    text-align: center;
  `,
  text: css`
    font-weight: bold;
    margin-top: 10px;
  `
};
