import React from "react";
import { Layout, Menu, Drawer } from "antd";
import { HomeOutlined, UserOutlined } from "@ant-design/icons";
import { css } from "emotion";
import { NavLink, useLocation } from "react-router-dom";
import { MOBILE_WIDTH } from "../constants";
import { useSelector, useDispatch } from "react-redux";
import { getDrawerOpen } from "./common.selectors";
import { closeDrawer } from "./commonSlice";

const { Sider } = Layout;

const Sidebar = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const isDrawerOpen = useSelector(getDrawerOpen);

  const handleDrawerClose = () => {
    dispatch(closeDrawer());
  };

  return (
    <>
      <Sider
        trigger={null}
        collapsible
        collapsed={true}
        className={styles.container}
      >
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[location.pathname]}
        >
          <Menu.Item key="/" icon={<HomeOutlined />}>
            <NavLink to="/">Order List</NavLink>
          </Menu.Item>
          <Menu.Item key="/userDetails" icon={<UserOutlined />}>
            <NavLink to="/userDetails">Profile</NavLink>
          </Menu.Item>
        </Menu>
      </Sider>
      <Drawer
        title="Menu"
        closable={true}
        visible={isDrawerOpen}
        placement="left"
        onClose={handleDrawerClose}
      >
        <Menu
          defaultSelectedKeys={[location.pathname]}
          onClick={handleDrawerClose}
        >
          <Menu.Item key="/" icon={<HomeOutlined />}>
            <NavLink to="/">Order List</NavLink>
          </Menu.Item>
          <Menu.Item key="/userDetails" icon={<UserOutlined />}>
            <NavLink to="/userDetails">Profile</NavLink>
          </Menu.Item>
        </Menu>
      </Drawer>
    </>
  );
};

export default Sidebar;

const styles = {
  container: css`
    height: 100vh;
    position: fixed;
    @media (max-width: ${MOBILE_WIDTH}) {
      display: none;
    }
  `,
  logo: css`
    width: 100px;
    height: 30px;
  `
};
