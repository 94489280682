import { makeURL, getToken } from "../../commons/appUtils";
import { SERVICE_METHODS } from "../../commons/constants";
import { axiosInstance } from "../../commons/axiosConfig";

export const getOtp$$ = data => {
  return axiosInstance({
    method: SERVICE_METHODS.POST,
    url: makeURL("/coupon/create_otp"),
    data
  });
};

export const verifyOtp$$ = data => {
  return axiosInstance({
    method: SERVICE_METHODS.POST,
    url: makeURL("/coupon/verify_otp"),
    data
  });
};

export const registerUser$$ = data => {
  return axiosInstance({
    method: SERVICE_METHODS.PUT,
    url: makeURL("/coupon/profile"),
    data,
    ...getToken()
  });
};
