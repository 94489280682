import React from "react";
import { Modal, Form, Input } from "antd";
import ThemeButton from "../../commons/themeComponents/ThemeButton";
import { SnippetsOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const ApplyCouponModal = props => {
  const [form] = Form.useForm();
  const history = useHistory();

  const handleSubmit = values => {
    props.closeModal();
    history.push(`/orderPunching/${values.coupon}`);
  };

  return (
    <Modal
      title="Apply Coupon"
      visible={true}
      onCancel={props.closeModal}
      footer={null}
      width={400}
    >
      <Form name="applyCouponForm" form={form} onFinish={handleSubmit}>
        <Form.Item
          name="coupon"
          rules={[{ required: true, message: "Please input your Coupon!" }]}
          hasFeedback
        >
          <Input
            prefix={<SnippetsOutlined />}
            placeholder="Coupon"
            size="large"
          />
        </Form.Item>
        <Form.Item>
          <ThemeButton
            size="large"
            block
            htmlType="submit"
            text="Submit Coupon"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ApplyCouponModal;
