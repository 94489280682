import React from "react";
import { css } from "emotion";

const MandatoryHeader = () => (
  <div className={styles.container}>
    <span>*</span>
  </div>
);

export default MandatoryHeader;

const styles = {
  container: css`
    color: red;
    font-size: 18px;
    display: flex;
    justify-content: flex-end;
    height: 20px;
  `
};
