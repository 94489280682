import React from "react";
import { css } from "emotion";
import { Menu, Dropdown } from "antd";
import {
  DownOutlined,
  MenuUnfoldOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearUser } from "../../features/auth/authSlice";
import { getCurrentUser } from "../../features/user/user.selectors";
import { MOBILE_WIDTH } from "../constants";
import { openDrawer } from "./commonSlice";

const Navbar = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(getCurrentUser);

  const logout = () => {
    localStorage.clear();
    dispatch(clearUser());
    history.replace("/login");
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={logout}>Log Out</Menu.Item>
    </Menu>
  );

  const handleMenu = () => {
    dispatch(openDrawer());
  };

  return (
    <div className={styles.container}>
      <span className={styles.logoView}>
        <MenuUnfoldOutlined className={styles.menuIcon} onClick={handleMenu} />
        <div className={styles.contactView}>
          <span>
            <PhoneOutlined />: +91 88821 54788
          </span>
          <span>@: offers@suprol.in</span>
        </div>
      </span>
      {user && (
        <Dropdown overlay={menu} trigger={["hover", "click"]}>
          <span className={styles.nameText}>
            {`${user.first_name} ${user.last_name}`} <DownOutlined />
          </span>
        </Dropdown>
      )}
    </div>
  );
};

export default Navbar;

const styles = {
  container: css`
    height: 50px;
    box-shadow: 0 1px 8px 0 rgba(34, 36, 38, 0.15);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 4%;
  `,
  nameText: css`
    font-weight: bold;
    cursor: pointer;
  `,
  logoView: css`
    display: flex;
    align-items: center;
  `,
  logo: css`
    width: 100px;
    height: 30px;
    margin-left: 50px;
    @media (max-width: ${MOBILE_WIDTH}) {
      margin-left: 0px;
      display: none;
    }
  `,
  menuIcon: css`
    display: none;
    font-size: 23px;
    margin-right: 10px;
    @media (max-width: ${MOBILE_WIDTH}) {
      display: flex;
    }
  `,
  contactView: css`
    margin-left: 50px;
    display: grid;
    @media (max-width: ${MOBILE_WIDTH}) {
      margin-left: 10px;
    }
  `,
};
