import { axiosInstance } from "../../commons/axiosConfig";
import { SERVICE_METHODS } from "../../commons/constants";
import { makeURL, getToken } from "../../commons/appUtils";

export const updateProfile$$ = data => {
  return axiosInstance({
    method: SERVICE_METHODS.PUT,
    url: makeURL("/coupon/profile"),
    data,
    ...getToken()
  });
};

export const updateAddress$$ = (data, addressId) => {
  return axiosInstance({
    method: SERVICE_METHODS.PUT,
    url: makeURL(`/coupon/address/update/${addressId}`),
    data,
    ...getToken()
  });
};

export const deleteAddress$$ = addressId => {
  return axiosInstance({
    method: SERVICE_METHODS.PUT,
    url: makeURL(`/coupon/address/delete/${addressId}`),
    ...getToken()
  });
};
