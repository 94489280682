import { useState } from "react";
import { API_STATUS } from "../../commons/constants";
import {
  initiatePayment$$,
  reportPaymentFailure$$,
  reportPaymentSuccess$$,
} from "./services";
import to from "await-to-js";
import { useHistory } from "react-router-dom";
import { errorNotification, successNotification } from "../../commons/appUtils";
import { getCurrentUser } from "../user/user.selectors";
import { useSelector } from "react-redux";

// Prod
const RAZOR_PAY_KEY = "rzp_live_4OWgQPOq7et6S9";

// Test
// const RAZOR_PAY_KEY = "rzp_test_BU1HpNAgqclDXJ";

export const usePaymentSuccess = (history) => {
  const [apiStatus, setApiStatus] = useState(API_STATUS.IDLE);

  const reportPaymentSuccess = async (payload, paymentID) => {
    setApiStatus(API_STATUS.LOADING);
    const [error, response] = await to(
      reportPaymentSuccess$$(payload, paymentID)
    );

    if (error) {
      setApiStatus(API_STATUS.REJECTED);
    } else {
      if (response.responseCode === 80) {
        setApiStatus(API_STATUS.RESOLVED);
        successNotification("Payment Success", "Payment done successfully.");
        history.push("/");
      } else {
        setApiStatus(API_STATUS.REJECTED);
        history.push("/");
      }
    }
  };

  return [apiStatus, reportPaymentSuccess];
};

export const usePaymentFailure = (history) => {
  const [apiStatus, setApiStatus] = useState(API_STATUS.IDLE);

  const reportPaymentFailure = async (paymentId) => {
    setApiStatus(API_STATUS.LOADING);
    const [error, response] = await to(reportPaymentFailure$$(paymentId));

    if (error) {
      setApiStatus(API_STATUS.REJECTED);
    } else {
      if (response.responseCode === 80) {
        setApiStatus(API_STATUS.RESOLVED);
        errorNotification("Payment Failure", "Failed to receive the payment.");
        history.push("/");
      } else {
        setApiStatus(API_STATUS.REJECTED);
        history.push("/");
      }
    }
  };

  return [apiStatus, reportPaymentFailure];
};

const useMakePayment = () => {
  const history = useHistory();
  const user = useSelector(getCurrentUser);

  const [paymentSuccessApiStatus, reportPaymentSuccess] = usePaymentSuccess(
    history
  );
  const [paymentFailureApiStatus, reportPaymentFailure] = usePaymentFailure(
    history
  );

  const makePayment = async (payload) => {
    const options = {
      key: RAZOR_PAY_KEY,
      currency: "INR",
      amount: payload.amount,
      order_id: payload.razorpay_order_id,
      name: "Suprol Lenses",
      description: "Thank for ordering from Suprol Lenses.",
      prefill: {
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
        contact: user.username,
      },
      theme: {
        color: "#4D33A1",
      },
      handler: (response) => reportPaymentSuccess(response, payload.payment_id),
      modal: {
        ondismiss: () => reportPaymentFailure(payload.payment_id),
      },
    };
    const razorpayInstance = new window.Razorpay(options);
    razorpayInstance.open();
  };

  return [makePayment, paymentSuccessApiStatus, paymentFailureApiStatus];
};

export const useInitiatePayment = () => {
  const [initiatePaymentApiStatus, setApiStatus] = useState(API_STATUS.IDLE);

  const [
    makePayment,
    paymentSuccessApiStatus,
    paymentFailureApiStatus,
  ] = useMakePayment();

  const initiatePayment = async (payload) => {
    setApiStatus(API_STATUS.LOADING);
    const [error, response] = await to(initiatePayment$$(payload));

    if (error) {
      setApiStatus(API_STATUS.REJECTED);
    } else {
      if (response.responseCode === 80) {
        setApiStatus(API_STATUS.RESOLVED);
        makePayment(response.data);
      } else {
        setApiStatus(API_STATUS.REJECTED);
      }
    }
  };

  return [
    initiatePaymentApiStatus,
    initiatePayment,
    paymentSuccessApiStatus,
    paymentFailureApiStatus,
  ];
};
