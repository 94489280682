import React, { useState } from "react";
import { Row, Col, Form, Input } from "antd";
import { LockOutlined, PhoneOutlined } from "@ant-design/icons";
import to from "await-to-js";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { regStyles as styles } from "./styles";
import ThemeButton from "../../commons/themeComponents/ThemeButton";
// import LogoList from "../../commons/commonComponents/LogoList";
import { API_STATUS } from "../../commons/constants";
import { getOtp$$, verifyOtp$$ } from "./services";
import { errorNotification, setItem } from "../../commons/appUtils";
import { setUser } from "./authSlice";
import { loginRules } from "./authValidations";
import MandatoryHeader from "../../commons/commonComponents/MandatoryHeader";

export const isNumberRegex = /^[0-9]*$/;

const { mobileNumberRules, otpRules } = loginRules;

const useGetOtp = () => {
  const [apiStatus, setApiStatus] = useState(API_STATUS.IDLE);
  const [showOtpField, setShowOtpField] = useState(false);

  const getOtp = async (data) => {
    setApiStatus(API_STATUS.LOADING);
    const [error, response] = await to(getOtp$$(data));
    if (error) {
      setApiStatus(API_STATUS.REJECTED);
      errorNotification(error.message);
    } else {
      setApiStatus(API_STATUS.RESOLVED);
      if (response.responseCode === 80) {
        setShowOtpField(true);
      }
    }
  };

  return [getOtp, apiStatus, showOtpField];
};

const useVerifyOtp = () => {
  const [apiStatus, setApiStatus] = useState(API_STATUS.IDLE);
  const history = useHistory();
  const dispatch = useDispatch();

  const verifyOtp = async (data) => {
    setApiStatus(API_STATUS.LOADING);
    const [error, response] = await to(verifyOtp$$(data));
    if (error) {
      setApiStatus(API_STATUS.REJECTED);
    } else {
      setApiStatus(API_STATUS.RESOLVED);
      if (response.responseCode === 80) {
        setItem("suprolStockUser", response.data);
        if (response.data.is_registered) {
          dispatch(setUser(response.data));
          history.push("/");
        } else {
          history.push("/register");
        }
      }
    }
  };

  return [verifyOtp, apiStatus];
};

const LoginPage = () => {
  const [form] = Form.useForm();
  const [getOtp, getOtpApiStatus, showOtpField] = useGetOtp();
  const [verifyOtp, verifyOtpApiStatus] = useVerifyOtp();

  const handleGetOtp = () => {
    form.validateFields().then(({ mobile_number }) => {
      const payload = { mobile_number };
      getOtp(payload);
    });
  };

  const handleVerifyOtp = () => {
    form.validateFields().then((values) => verifyOtp(values));
  };

  return (
    <div className={styles.container}>
      <div className={styles.innerView}>
        <div className={styles.logoList}>{/* <LogoList /> */}</div>
        <Row justify="center" align="middle">
          <Col xs={22} sm={22} md={22} lg={8} xl={8}>
            <div className={styles.headingView}>
              <h1 className={styles.heading}>Suprol Offer</h1>
            </div>
            <Form name="loginForm" form={form}>
              <div className={styles.formView}>
                <MandatoryHeader />
                <Form.Item
                  name="mobile_number"
                  rules={mobileNumberRules}
                  hasFeedback
                >
                  <Input
                    prefix={<PhoneOutlined />}
                    placeholder="Registered Phone Number"
                    size="large"
                  />
                </Form.Item>
                {!showOtpField && (
                  <Form.Item>
                    <ThemeButton
                      size="large"
                      type="primary"
                      block
                      htmlType="submit"
                      text="Get OTP"
                      onClick={handleGetOtp}
                      loading={getOtpApiStatus === API_STATUS.LOADING}
                    />
                  </Form.Item>
                )}
                {showOtpField && (
                  <>
                    <MandatoryHeader />
                    <Form.Item name="otp" rules={otpRules} hasFeedback>
                      <Input
                        prefix={<LockOutlined />}
                        placeholder="OTP"
                        size="large"
                      />
                    </Form.Item>
                    <Form.Item>
                      <ThemeButton
                        size="large"
                        block
                        htmlType="submit"
                        text="Submit"
                        loading={verifyOtpApiStatus === API_STATUS.LOADING}
                        onClick={handleVerifyOtp}
                      />
                    </Form.Item>
                  </>
                )}
              </div>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default LoginPage;
