import React, { useState, useEffect } from "react";
import to from "await-to-js";
import { useParams } from "react-router-dom";
import PunchingForm from "./PunchingForm";
import Sidebar from "../../commons/commonComponents/Sidebar";
import Navbar from "../../commons/commonComponents/Navbar";
import { API_STATUS } from "../../commons/constants";
import { checkCouponValidity$$ } from "./services";
import ErrorMessage from "../../commons/commonComponents/ErrorMessage";
import Loader from "../../commons/commonComponents/Loader";
import { useCouponDataModelCreator } from "../../commons/modelCreators";

const useCheckCouponValidity = () => {
  const [apiStatus, setApiStatus] = useState(API_STATUS.IDLE);
  const [couponData, setCouponData] = useState({});
  const { couponId } = useParams();

  const checkCouponValidity = async () => {
    setApiStatus(API_STATUS.LOADING);
    const [error, response] = await to(checkCouponValidity$$(couponId));

    if (error) {
      setApiStatus(API_STATUS.REJECTED);
    } else {
      if (response.responseCode === 80) {
        setApiStatus(API_STATUS.RESOLVED);
        setCouponData(response.data);
      } else {
        setApiStatus(API_STATUS.REJECTED);
      }
    }
  };

  useEffect(() => {
    checkCouponValidity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [apiStatus, couponData];
};

const OrderPunching = () => {
  const [couponValidityApiStatus, couponData] = useCheckCouponValidity();
  const couponDataFromModel = useCouponDataModelCreator(couponData);
  return (
    <div>
      <Sidebar />
      <Navbar />
      {(couponValidityApiStatus === API_STATUS.LOADING ||
        couponValidityApiStatus === API_STATUS.IDLE) && <Loader />}
      {couponValidityApiStatus === API_STATUS.REJECTED && (
        <ErrorMessage
          heading="Please check the coupon code."
          description="The code you have entered is either invalid or has already been redeemed."
          routeName="/"
          routeText="Go to home"
        />
      )}
      {couponValidityApiStatus === API_STATUS.RESOLVED && (
        <PunchingForm couponData={couponDataFromModel} />
      )}
    </div>
  );
};

export default OrderPunching;
