import React from "react";
import { Modal, Form, Input, Row, Col, Select } from "antd";
import ThemeButton from "../../commons/themeComponents/ThemeButton";
import { API_STATUS, stateList } from "../../commons/constants";
import { registrationRules } from "../auth/authValidations";
import { HomeOutlined, NumberOutlined } from "@ant-design/icons";
import { useAddAddress } from "../user/userHooks";

const { Option } = Select;

const {
  addressLineRules,
  cityRules,
  stateRules,
  pincodeRules
} = registrationRules;

const ApplyCouponModal = props => {
  const [form] = Form.useForm();
  const [addAddressApiStatus, addAddress] = useAddAddress(props.closeModal);

  return (
    <Modal
      title="Add Address"
      visible={true}
      onCancel={props.closeModal}
      footer={null}
      width={600}
    >
      <Form name="addAddressForm" form={form} onFinish={addAddress}>
        <Row gutter={12}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item
              name={["address", "address_line"]}
              rules={addressLineRules}
              hasFeedback
            >
              <Input
                prefix={<HomeOutlined />}
                placeholder="Address"
                size="large"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item name={["address", "city"]} rules={cityRules} hasFeedback>
              <Input
                prefix={<HomeOutlined />}
                placeholder="City"
                size="large"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item
              name={["address", "state"]}
              rules={stateRules}
              hasFeedback
            >
              <Select placeholder="Select State" size="large" showSearch={true}>
                {stateList.map(state => (
                  <Option value={state.value} key={state.value}>
                    {state.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item
              name={["address", "pincode"]}
              rules={pincodeRules}
              hasFeedback
            >
              <Input
                prefix={<NumberOutlined />}
                placeholder="Pincode"
                size="large"
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <ThemeButton
            size="large"
            block
            htmlType="submit"
            text="Add Address"
            loading={API_STATUS.LOADING === addAddressApiStatus}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ApplyCouponModal;
