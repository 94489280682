export const stringRegex = /^[a-z]+$/i;
export const isNumberRegex = /^[0-9]*$/;
export const phoneNumberRegex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

export const loginRules = {
  mobileNumberRules: [
    { required: true, message: "Please input your Number!" },
    {
      pattern: phoneNumberRegex,
      message: "Please input correct Phone Number!"
    },
    {
      len: 10,
      message: "Number must contain 10 digits!"
    }
  ],
  otpRules: [
    { required: true, message: "Please input OTP!" },
    {
      len: 6,
      message: "OTP must contain 6 digits!"
    },
    {
      pattern: isNumberRegex,
      message: "Please input Number!"
    }
  ]
};

export const registrationRules = {
  firstNameRules: [
    {
      required: true,
      message: "Please input your First Name!"
    },
    {
      min: 3,
      message: "Atleast 3 characters required!"
    },
    {
      max: 15,
      message: "Atmost 15 characters allowed!"
    },
    {
      pattern: stringRegex,
      message: "Please input valid First Name!"
    }
  ],
  lastNameRules: [
    {
      required: true,
      message: "Please input your Last Name!"
    },
    {
      min: 3,
      message: "Atleast 3 characters required!"
    },
    {
      max: 15,
      message: "Atmost 15 characters allowed!"
    },
    {
      pattern: stringRegex,
      message: "Please input valid Last Name!"
    }
  ],
  agentNameRules: [
    {
      required: true,
      message: "Please input Agent Name!"
    },
    {
      min: 3,
      message: "Atleast 3 characters required!"
    },
    {
      max: 50,
      message: "Atmost 50 characters allowed!"
    }
  ],
  storeNameRules: [
    {
      required: true,
      message: "Please input your Store Name!"
    },
    {
      min: 3,
      message: "Atleast 3 characters required!"
    },
    {
      max: 50,
      message: "Atmost 50 characters allowed!"
    }
  ],
  emailRules: [
    { required: true, message: "Please input your Email!" },
    { type: "email", message: "Please input valid Email!" }
  ],
  addressLineRules: [
    {
      required: true,
      message: "Please input your Address!"
    },
    {
      min: 3,
      message: "Atleast 3 characters required!"
    },
    {
      max: 100,
      message: "Atmost 100 characters allowed!"
    }
  ],
  cityRules: [
    { required: true, message: "Please input your City!" },
    {
      min: 3,
      message: "Atleast 3 characters required!"
    },
    {
      max: 20,
      message: "Atmost 20 characters allowed!"
    }
    // {
    //   pattern: stringRegex,
    //   message: "Please input valid City!"
    // }
  ],
  stateRules: [{ required: true, message: "Please select your State!" }],
  pincodeRules: [
    {
      required: true,
      message: "Please input your Pincode!"
    },
    {
      len: 6,
      message: "Pincode must contain 6 digits!"
    },
    {
      pattern: isNumberRegex,
      message: "Please input Number!"
    }
  ],
  gstRules: [
    {
      len: 15,
      message: "Please enter 15 character GST Number!"
    }
  ]
};
