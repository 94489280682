import React from "react";
import { css } from "emotion";
import { MOBILE_WIDTH } from "../constants";

const Footer = () => (
  <footer className={styles.container}>
    <span>
      {/* Developed in collaboration with */}
      <img
        src={require("../../assets/suprolLogo.jpg")}
        alt="suprolLogo"
        className={styles.logo}
      />
      <img
        src={require("../../assets/boltLogo.png")}
        alt="boltLogo"
        className={styles.logo}
      />
      {/* lenses */}
    </span>
  </footer>
);

export default Footer;

const styles = {
  container: css`
    padding-right: 2%;
    margin-top: 5%;
    font-weight: bold;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &:after {
      height: 100px;
    }
    @media (max-width: ${MOBILE_WIDTH}) {
      padding: 0 20px;
    }
  `,
  logo: css`
    height: 30px;
    width: 110px;
    margin: 0px 15px;
  `,
};
