import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../../features/auth/authSlice";
import modalReducer from "../../features/modals/ModalSlice";
import commonReducer from "../commonComponents/commonSlice";

const rootReducer = {
  auth: authReducer,
  modals: modalReducer,
  common: commonReducer
};

const store = configureStore({
  reducer: rootReducer
});

export default store;
