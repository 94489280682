import React from "react";
import { css } from "emotion";
import { Link } from "react-router-dom";

const ErrorMessage = ({ heading, description, routeName, routeText }) => (
  <div className={styles.container}>
    <div className={styles.innerView}>
      <h2>{heading ? heading : "OOPS! something went wrong."}</h2>
      <p>{description ? description : "Please try again or contact admin"}</p>
      {routeName && routeText && <Link to={routeName}>{routeText}</Link>}
    </div>
  </div>
);

export default ErrorMessage;

const styles = {
  container: css`
    width: 100%;
    position: absolute;
    top: 42%;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  innerView: css`
    text-align: center;
    font-weight: bold;
  `
};
