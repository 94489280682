import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
// import { createBrowserHistory } from "history";
import { css } from "emotion";
import PrivateRoute from "./PrivateRoute";
import LoginPage from "../../features/auth/LoginPage";
import Registration from "../../features/auth/Registration";
import OrderListing from "../../features/orderDetails/OrderListing";
import OrderPunching from "../../features/orderPunching/OrdersPunching";
import OrderDetails from "../../features/orderDetails/OrderDetails";
import ScrollToTop from "./ScrollToTop";
import UserDetails from "../../features/user/UserDetails";
import ModalManager from "../../features/modals/ModalManager";
import Footer from "../commonComponents/Footer";
import NotRegisteredRoute from "./NotRegisteredRoutes";

// export const history = createBrowserHistory();

const AppRouter = () => {
  return (
    <BrowserRouter>
      <ModalManager />
      <ScrollToTop />
      <div className={styles.mainView}>
        <Switch>
          <Route path="/login">
            <LoginPage />
          </Route>
          <NotRegisteredRoute path="/register">
            <Registration />
          </NotRegisteredRoute>
          <PrivateRoute path="/" exact>
            <OrderListing />
          </PrivateRoute>
          <PrivateRoute path="/orderPunching/:couponId">
            <OrderPunching />
          </PrivateRoute>
          <PrivateRoute path="/orderDetails/:orderId">
            <OrderDetails />
          </PrivateRoute>
          <PrivateRoute path="/userDetails">
            <UserDetails />
          </PrivateRoute>
        </Switch>
      </div>
      <Footer />
    </BrowserRouter>
  );
};

export default AppRouter;

const styles = {
  mainView: css`
    min-height: 100%;
    /* equal to footer height */
    margin-bottom: -100px;
    &:after {
      content: "";
      display: block;
      height: 100px;
    }
  `
};
